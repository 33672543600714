import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'raspberry-wifi-log-action',
  templateUrl: './raspberry-wifi-log-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RaspberryWifiLogActionComponent {
  @Output() readonly wifiListRefresh = new EventEmitter();

  refresh() {
    this.wifiListRefresh.emit();
  }
}
