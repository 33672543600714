import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { WifiList } from 'app/models/printing.model';

@Component({
  selector: 'raspberry-wifi-log-list',
  templateUrl: './raspberry-wifi-log-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RaspberryWifiLogListComponent {
  @Input() wifiList: WifiList[];
  @Output() readonly routeNavigate = new EventEmitter();
  @Output() readonly wifiDisconnect = new EventEmitter();

  offset = 0;
  handlePages(event) {
    this.offset = event.offset;
  }

  connect(ssid: string) {
    this.routeNavigate.emit(ssid);
  }

  disconnect(ssid: string) {
    this.wifiDisconnect.emit(ssid);
  }
}
