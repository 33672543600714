@if (wifiList) {
  <div class="table-wrapper">
    <ngx-datatable
      class="material"
      [rows]="wifiList"
      [footerHeight]="30"
      [columnMode]="'force'"
      [rowHeight]="'auto'"
      (page)="handlePages($event)"
      [limit]="4"
      [count]="wifiList.length"
      [offset]="offset"
    >
      <ngx-datatable-column [maxWidth]="50" [sortable]="false" [draggable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          @if (row.inUse === '*') {
            <mat-icon svgIcon="link"></mat-icon>
          }
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="SSID" [sortable]="false" [draggable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.ssid }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Signal" [maxWidth]="100" [sortable]="false" [draggable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <mat-icon [svgIcon]="row.icon"></mat-icon>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Security" [maxWidth]="150" [sortable]="false" [draggable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.security }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [maxWidth]="150" [name]="'Action' | translate" [sortable]="false" [draggable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          @if (row.inUse !== '*') {
            <button [matTooltip]="'connect' | translate" mat-flat-button color="accent" style="width: 100px" (click)="connect(row.ssid)">Connect</button>
          } @else {
            <button [matTooltip]="'disconnect' | translate" mat-flat-button color="warn" style="width: 100px" (click)="disconnect(row.ssid)">Disconnect</button>
          }
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
}
@if (!wifiList) {
  <div class="no-table-content">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
}
