<h2 mat-dialog-title>{{ 'connection' | translate }}</h2>
<mat-dialog-content>
  <connect-wifi-form #connectWifiForm [ssid]="ssid" (formSubmit)="handleFormInfo($event)"></connect-wifi-form>
</mat-dialog-content>

<mat-dialog-actions>
  @if (loading | async) {
    <mat-spinner></mat-spinner>
  }
  <button mat-raised-button color="accent" (click)="connectWifi()" [disabled]="connectWifiForm.connectWifiForm.invalid || (loading | async)">
    {{ 'connection' | translate }}
  </button>
  <button mat-raised-button color="warn" (click)="leaveDialog()">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
