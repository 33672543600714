import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HandleErrorsService, PrintingService } from 'app/app-services';
import { WiFiCredentials } from 'app/models/printing.model';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'connect-wifi-dialog',
  templateUrl: './connect-wifi-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectWifiDialogComponent implements OnInit {
  loading = new ReplaySubject<boolean>(1);

  formInfo: WiFiCredentials = null;

  queryParams: any;

  constructor(
    protected route: ActivatedRoute,
    protected snackBar: MatSnackBar,
    protected handleErrors: HandleErrorsService,
    protected printingService: PrintingService,
    protected translateService: TranslateService,
    private readonly dialogRef: MatDialogRef<ConnectWifiDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public ssid: string,
  ) {}

  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;
  }

  handleFormInfo(formInfo: { ssid: string; password: string }) {
    this.formInfo = formInfo;
  }

  leaveDialog() {
    this.dialogRef.close(null);
  }

  connectWifi() {
    this.loading.next(true);
    this.printingService.connectWifi(this.formInfo).subscribe({
      next: () => {
        this.translateService.get('wifi-connect-message-success').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
        this.leaveDialog();
      },
      error: err => {
        this.loading.next(false);
        this.handleErrors.handleErrors(err);
      },
    });
  }
}
