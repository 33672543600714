import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WiFiCredentials } from 'app/models/printing.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'connect-wifi-form',
  templateUrl: './connect-wifi-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectWifiFormComponent implements OnChanges, OnDestroy {
  @Input() ssid: string;
  @Output() readonly formSubmit = new EventEmitter<WiFiCredentials>();
  connectWifiForm: FormGroup;
  ngUnsubscribe: Subscription[] = [];
  showPassword = false;

  ngOnChanges() {
    this.buildForm();
    this.ngUnsubscribe.push(
      this.connectWifiForm.valueChanges.subscribe(form => {
        this.formSubmit.emit(form);
      }),
    );
  }

  buildForm() {
    this.connectWifiForm = new FormGroup({
      ssid: new FormControl(this.ssid, Validators.required),
      password: new FormControl(null, Validators.required),
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.forEach(res => res.unsubscribe);
  }
}
