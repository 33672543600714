<form [formGroup]="connectWifiForm">
  <mat-form-field>
    <mat-label translate="SSID" />
    <input matInput type="text" formControlName="ssid" updateOnBlur />
    @if (connectWifiForm.controls['ssid'].hasError('required')) {
      <mat-error> {{ 'required' | translate }} </mat-error>
    }
  </mat-form-field>

  <div class="showPassword">
    <mat-form-field>
      <mat-label translate="Password" />
      <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password" updateOnBlur />
      @if (connectWifiForm.controls['password'].hasError('required')) {
        <mat-error> {{ 'required' | translate }} </mat-error>
      }
      <mat-icon
        matSuffix
        [color]="connectWifiForm.get('password')?.hasError('*', ['touched', 'invalid']) ? 'warn' : 'primary'"
        (mousedown)="showPassword = true"
        (mouseleave)="showPassword = false"
        (mouseup)="showPassword = false"
        [svgIcon]="!showPassword ? 'visibility_off' : 'visibility'"
      ></mat-icon>
    </mat-form-field>
  </div>
</form>
