export enum NotificationType {
  SERVER_ERROR = 'server-error',
  NOT_FOUND = 'not-found',
  NO_AUTH = 'no-auth',
  NO_ACCES = 'no-acces',
  INCOMPLETE_MESSAGE = 'incomplete-message',
  CLIENT_ERROR = 'client-error',
  LOGIN_SUCCES = 'login-succes',
  LOGOUT = 'logout',
  INCORRECT_LOGIN = 'incorrect-email-password',
  PASSWORD_MESSAGE_SUCCES = 'password-message-succes',
  INCORRECT_PASSWORD = 'incorrect-password',
  NO_DATA = 'no-data',
  TOTAL = 'total',
  MODIFICATION_MESSAGE_SUCCES = 'modification-message-succes',
  UPDATE_AVAILABLE = 'update-available',
  PRINT_COMPLETED = 'print-completed',
  PRINT_FAILED = 'print-failed',
  PRINTER_UNCLAIMED = 'printer-unclaimed',
  DISCONNECT_DEVICE = 'disconnect-device',
  PRINTER_NOT_CONNECTED = 'printer-not-connected',
}
