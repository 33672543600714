import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { HandleErrorsService, PrintingService } from 'app/app-services';
import { ConnectWifiDialogComponent } from 'app/connect-wifi-dialog/connect-wifi-dialog.component';
import { WifiList } from 'app/models/printing.model';
import { map, Observable, ReplaySubject } from 'rxjs';

@Component({
  selector: 'raspberry-wifi-log',
  templateUrl: './raspberry-wifi-log.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RaspberryWifiLogComponent implements OnInit {
  loading = new ReplaySubject<boolean>(1);
  constructor(
    protected printingService: PrintingService,
    protected handleErrors: HandleErrorsService,
    protected dialog: MatDialog,
    protected snackBar: MatSnackBar,
    protected translateService: TranslateService,
  ) {}
  wifiList$: Observable<WifiList[]>;
  ngOnInit() {
    this.wifiListRefresh();
  }

  navigate(ssid: string) {
    const config: MatDialogConfig = { width: '25%', data: ssid };
    this.dialog.open(ConnectWifiDialogComponent, config);
  }

  disconnectWifi(ssid: string) {
    this.loading.next(true);
    this.printingService.disconnectWifi(ssid).subscribe({
      next: () => {
        this.loading.next(false);
        this.translateService.get('wifi-disconnect-message-success').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
      },
      error: err => {
        this.loading.next(false);
        this.handleErrors.handleErrors(err);
      },
    });
  }

  wifiListRefresh() {
    this.wifiList$ = this.printingService.getWiFiList().pipe(
      map(wifiList =>
        wifiList.map(wifi => {
          let icon = '';

          if (wifi.signal >= 80) {
            icon = 'network_wifi';
          } else if (wifi.signal >= 60) {
            icon = 'network_wifi_3_bar';
          } else if (wifi.signal >= 40) {
            icon = 'network_wifi_2_bar';
          } else if (wifi.signal >= 20) {
            icon = 'network_wifi_1_bar';
          } else {
            icon = 'signal_wifi_statusbar_null';
          }

          return {
            ...wifi,
            icon,
          };
        }),
      ),
    );
  }
}
