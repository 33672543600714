import { Component } from '@angular/core';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SwUpdaterService } from './app-services/sw-updater.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private swUpdaterService: SwUpdaterService,
  ) {
    iconRegistry.addSvgIconResolver((name: string): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
      return sanitizer.bypassSecurityTrustResourceUrl(`assets/mat-icons/${name}.svg`);
    });
    this.swUpdaterService.alertSwUpdate();
    this.lockScreenOrientation();
  }

  private lockScreenOrientation() {
    (screen.orientation as any).lock('landscape').catch(error => console.info('Orientation lock failed:', error));
  }
}
